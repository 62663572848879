<template>
  <master-layout :showBack="false" smallTitle="fine" bigTitle="Questionario">
    <div
      class="flex flex-column justify-content-between align-items-stretch"
      style="height: calc(100vh - 200px)"
    >
      <div
        class="card w-full flex flex-column justify-content-center align-items-center"
        style="padding: 1.5rem 2rem; margin-top: 36px"
      >
        <div class="w-full flex justify-content-center align-items-center mb-4">
          <img
            src="../../../public/assets/img/danger.png"
            class="danger-image"
          />
        </div>
        <div class="text-align-center font-bold">
          Do you want to submit your answers?
        </div>
      </div>

      <div class="p-4">
        <div v-for="(r, index) in results" :key="index" class="mb-4">
          <div class="text-align-center mb-2">{{ r.domanda }}</div>
          <!-- Rating statico -->
          <div class="static-rating-container">
            <div v-for="x in 5" :key="x" class="star-container">
              <img
                :src="
                  x <= r.valore
                    ? '/assets/img/fullStar.png'
                    : '/assets/img/emptyStar.png'
                "
                class="star"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="button-container">
        <div class="p-1 w-full">
          <button
            @click="restartQuiz"
            style="
              background-color: white;
              border: 1px solid var(--ion-color-primary);
            "
            class="w-full flex justify-content-center p-d"
          >
            No
          </button>
        </div>
        <div class="p-1">
          <button
            style="border: 1px solid var(--ion-color-primary)"
            @click="confirmSubmit"
            class="w-full flex justify-content-center button-primary p-d"
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  </master-layout>

  <!-- <surveyResult>
    <template #header> -->
  <!-- </template> -->

  <!-- <template #footer> -->
  <!-- Bottoni per reiniziare e confermare -->

  <!-- </template>
  </surveyResult> -->
</template>

<script setup>
import { useRouter, useRoute } from "vue-router";
import { computed } from "vue";
import { useGeneralStore } from "../../stores/useGeneralStore";

import surveyResult from "./layout/surveyResult.vue";

import Button from "./components/Button.vue";
import { routerKey } from "vue-router";

import MasterLayout from "../../components/MasterLayout.vue";

const router = useRouter();
const route = useRoute();

const generalStore = useGeneralStore();

const currentSurvey = computed(() => generalStore.currentSurvey);

const results = computed(() => {
  let res = [];

  for (let r of currentSurvey.value.risposte) {
    res.push({ domanda: r.domanda.t_domanda["it-IT"], valore: r.valore });
  }

  return res;
});

// Metti qui la logica per i pulsanti
function restartQuiz() {
  router.push("/pk-survey/" + route.params.idSurvey);
}

function confirmSubmit() {
  router.push("/pk-surveys");
}
</script>

<style scoped>
.danger-image {
  width: 48px;
  height: 48px;
}

.card {
  border-radius: 10px;
  background-color: white;
  padding: 20px 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 2;
  position: relative;
  min-height: 100px;
}

.static-rating-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.star-container {
  margin: 0 0.3rem;
}

.star {
  width: 24px;
  height: 24px;
}

.button-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 2rem;
}

.btn {
  padding: 0.8rem 2rem;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.restart-btn {
  background-color: #ffdddd;
}

.restart-btn:hover {
  background-color: #ffbbbb;
}

.submit-btn {
  background-color: #d4edda;
}

.submit-btn:hover {
  background-color: #c3e6cb;
}
</style>
